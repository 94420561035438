<template>
  <div class="wet-section wet-main wet-main-custom">
    <div class="container">
      <div class="wet-main-content">
        <div class="wet-section-title text-center">
          <h1 class="empty-ph">{{data.title}}</h1>
        </div>
        <sliderComponent :current.sync="current" :crops="crops" />
        <formComponent
          :data="data.form"
          :button="data.button"
          :buttonDataAttr="data.buttonDataAttr"
          :current.sync="current"
          :period="period"
          :volksfests="volksfests"
          :businesses="businesses"
          :crops="crops"
          :timesSelector="timesSelector"
          :formDataSynced.sync="formDataSynced"
          :initDatesSynced.sync="initDates"
          @formData="$emit('formData', $event)"
          @order="$emit('order')"
        />
        <slot></slot>
        <videoComponent
          v-if="data.video"
          @videomodal="$emit('videomodal', true)"
          :data="data.video"
        />
        <input type="hidden" id="video_youtube_id" name="video_youtube_id" value="rnFxfpL92gU" />
      </div>
    </div>
  </div>
</template>
<script>
import sliderComponent from '@/components/sections/main/sliderComponent.vue';
import formComponent from '@/components/sections/main/formComponent.vue';
import videoComponent from '@/components/sections/main/videoComponent.vue';
import { mapState } from 'vuex';

export default {
  components: {
    sliderComponent,
    formComponent,
    videoComponent,
  },
  props: {
    data: Object,
    period: Boolean,
    volksfests: Array,
    businesses: Array,
    timesSelector: Boolean,
    currentCrop: {
      type: String,
      default: '',
    },
    formData: {
      type: Object,
      default: () => {},
    },
    initDatesSynced: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState(['strings']),
    ...mapState({
      crops: function fn(state) {
        return (state.settings.crops.length ? state.settings.crops : this.defaultCrops);
      },
    }),
    defaultCrops() {
      let crops = 4;
      switch (this.$route.name) {
      case 'rain':
        crops = 3;
        break;
      default:
        break;
      }
      return Array(crops).fill({});
    },
    current: {
      set(val) {
        this.$emit('update:currentCrop', val);
        this.$set(this.formDataSynced, 'crop', val);
      },
      get() {
        return this.currentCrop;
      },
    },
    formDataSynced: {
      set(val) {
        this.$emit('update:formData', val);
      },
      get() {
        return this.formData;
      },
    },
    initDates: {
      set(val) {
        this.$emit('update:initDatesSynced', val);
      },
      get() {
        return this.initDatesSynced;
      },
    },
  },
};
</script>
<style lang="scss">
</style>
