<template>
  <form
    class="wet-order-form"
    autocomplete="off"
    @submit.prevent="$emit('order')"
    :disabled="crops[0] && !crops[0].id"
  >
    <div class="row">
      <div
        class="col-lg-4"
        :class="{
          'd-md-none': crops.length < 5
        }"
      >
        <div class="form-group">
          <label class="form-group-title empty-ph">{{data.product_title}}</label>
          <div class="custom-vegetation-select">
            <div
              id="drought-form-select"
              class="nice-select vegetation-period"
              :class="selectOpen ? 'open' : ''"
              @click="selectOpen = !selectOpen"
            >
              <span class="current">{{selectedItemCrop.title}}</span>
              <ul class="list">
                <li
                  class="option"
                  :id="`wet-prod-option-${id + 1}`"
                  :class="formData.crop === item.id ? 'selected focus' : ''"
                  v-for="(item, id) in crops"
                  :key="id"
                  @click="$emit('update:current', item.id)"
                >{{item.title}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="{
          'col-lg-12': crops.length < 5,
          'col-lg-8': crops.length >= 5
        }"
        style="z-index:1;"
      >
        <period-component
          v-if="period"
          :calendLang="calendLang"
          :startText="startText"
          :endText="endText"
          :startDate.sync="startDate"
          :endDate.sync="endDate"
          :defaultStartDate="defaultStartDate"
          :defaultEndDate="defaultEndDate"
          :current="current"
          :crops="crops"
        />
        <div v-else class="row">
          <div
            v-if="current === 'volksfest'"
            class="col-12 col-sm-6 flex flex-column align-items-center"
          >
            <div class="form-group-title empty-ph">
              {{strings.commonApp && strings.commonApp.calendar.choose_volksfest}}
            </div>
            <volksfest-component
              :items="volksfests"
              v-model="volksfest"
              :dates.sync="initDates"
            />
          </div>
          <div
            class="flex col-12 flex-column align-items-center"
            :class="{
              'col-sm-6': current === 'volksfest',
            }"
          >
            <div class="form-group-title empty-ph">
              {{strings.commonApp && strings.commonApp.calendar.choose_period}}
            </div>
            <dates-component
              class="mb-3"
              :calendLang="calendLang"
              :initDates="initDates"
              @dates="(ds) => dates = ds"
              :startText="startText"
              :scenario="current"
            />
          </div>
          <!-- times selector -->
          <div v-if="timesSelector" class="col-12 flex flex-column align-items-center">
            <div class="form-group-title empty-ph">
              {{strings.commonApp && strings.commonApp.calendar.choose_times}}
            </div>
            <product-form-time-slider
              ref="timeSlider"
              v-model="timeRange"
              :style="{
                maxWidth: '500px',
              }"
              :minTimeRange="minTimeRange"
              :maxTimeRange="maxTimeRange"
            />
          </div>
          <!-- dates selected -->
          <div class="col-12 flex my-2 flex-column align-items-center">
            <div class="empty-ph">{{datesValue}}</div>
            <div v-if="timesSelector" class="wet-note">{{timesValue}}</div>
          </div>
          <!-- busines selector -->
          <div
            v-if="$route.name === 'custom'"
            class="col-12 flex flex-column align-items-center"
          >
            <div class="form-group-title empty-ph text-center">
              {{strings.sections && strings.sections.list_of_branches_title}}
            </div>
            <list-component
              :items="businesses"
              style="max-width:500px;"
              v-model="business"
            />
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group text-center form-group-submit">
          <button v-bind="buttonAttrs">{{button}}</button>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
// init datepicker langs
import { en, de } from 'vuejs-datepicker/dist/locale';
import periodComponent from '@/components/sections/main/periodComponent.vue';
import datesComponent from '@/components/sections/main/datesComponent.vue';
import { periodHumanize } from '@/common/snippets';
import volksfestComponent from '@/components/volksfestsComponent.vue';
import listComponent from '@/components/ListComponent.vue';
import { mapState } from 'vuex';
import common from '@/mixins/common';
import productFormTimeSlider from '@/components/productFormTimeSlider.vue';
import LogService from '@/services/LogService';

export default {
  mixins: [common],
  components: {
    periodComponent,
    datesComponent,
    volksfestComponent,
    listComponent,
    productFormTimeSlider,
  },
  props: {
    data: Object,
    button: String,
    buttonDataAttr: {
      type: Object,
      default: () => {},
    },
    current: String,
    period: Boolean,
    volksfests: Array,
    businesses: Array,
    crops: Array,
    timesSelector: Boolean,
    formDataSynced: {
      type: Object,
      default: () => {},
    },
    initDatesSynced: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      en,
      de,
      selectOpen: false,
      volksfest: {},
      defaultStartDate: null,
      defaultEndDate: null,
    };
  },
  computed: {
    ...mapState(['strings']),
    validation() {
      const type = this.current in this.$store.state.settings.calendar
        ? this.current
        : Object.keys(this.$store.state.settings.calendar)[0];
      return this.$store.state.settings.calendar[type] || {};
    },
    calendLang() {
      // get current lang from url path
      return this[this.$route.params.lang] || this.en;
    },
    startText() {
      const start = this.crops.filter((el) => el.id === this.formData.crop)[0]?.start_text;
      return this.data.date_from || start;
    },
    endText() {
      const end = this.crops.filter((el) => el.id === this.formData.crop)[0]?.end_text;
      return this.data.date_to || end;
    },
    selectedItemCrop() {
      // return current item
      let item = {};
      if (this.formData && this.crops) {
        const { crop } = this.formData;
        item = this.crops.filter((el) => el.id === crop)[0] || {};
      }
      return item;
    },
    startDate: {
      get() {
        return this.formData.startDate;
      },
      set(val) {
        if (!(val instanceof Date)) return;
        this.$set(this.formData, 'startDate', val);
        if (!val || !this.formData.endDate) return;
        const dates = this.$moment.range(val, this.formData.endDate).by('days');
        // this.$set(this.formData, 'dates', Array.from(dates).map((d) => d));
        this.dates = Array.from(dates);
      },
    },
    endDate: {
      get() {
        return this.formData.endDate;
      },
      set(val) {
        if (!(val instanceof Date)) return;
        this.$set(this.formData, 'endDate', val);
        if (!this.formData.startDate || !val) return;
        const dates = this.$moment.range(this.formData.startDate, val).by('days');
        // this.$set(this.formData, 'dates', Array.from(dates).map((d) => d));
        this.dates = Array.from(dates);
      },
    },
    dates: {
      get() {
        return this.formData.dates;
      },
      set(val) {
        const dates = val.length > 0 ? [...val] : [];
        if (dates?.length > 0) dates.sort((a, b) => (a.isAfter(b) ? 1 : -1));
        this.$set(this.formData, 'dates', dates);
      },
    },
    datesValue() {
      let selected = this.strings.commonApp?.calendar?.no_selected_dates;
      const days = this.dates?.length > 1
        ? this.strings.commonApp?.days : this.strings.commonApp?.day;
      if (this.dates?.length > 0) {
        selected = this.format(
          this.strings.commonApp?.calendar?.selected_dates_tpl,
          this.dates?.length,
          days,
          this.periodHumanize(this, this.dates),
        );
      }
      return selected;
    },
    timesValue() {
      const times = `${this.timeRange[0]}:00 - ${this.timeRange[1]}:00`;
      return this.format(this.strings.commonApp?.calendar?.between_hours, times);
    },
    buttonDisabled() {
      /** insure button bisabled */
      const disabledBySettings = this.formData.dates?.length < this.validation.min_contract_length;

      return (this.crops[0] && !this.crops[0]?.id)
        || !this.formData.dates
        || disabledBySettings
        || (this.$route.name === 'custom' && !this.business?.id);
    },
    buttonAttrs() {
      return {
        id: 'main-two-button',
        class: 'wet-button wet-button-up wet-button-xs-full empty-ph',
        style: 'min-width:11rem',
        [this.buttonDataAttr?.name]: this.buttonDataAttr?.value,
        disabled: this.buttonDisabled,
      };
    },
    formData: {
      set(val) {
        this.$emit('update:formDataSynced', val);
      },
      get() {
        return this.formDataSynced;
      },
    },
    timeRange: {
      get() {
        return this.formData.hours || [];
      },
      set(v) {
        this.$set(this.formData, 'hours', v);
      },
    },
    business: {
      set(val) {
        this.$set(this.formData, 'business', val);
      },
      get() {
        return this.formData.business;
      },
    },
    initDates: {
      set(val) {
        this.$emit('update:initDatesSynced', val);
      },
      get() {
        return this.initDatesSynced;
      },
    },
    minTimeRange() {
      return this.validation.time_settings[0].min_hours || 0;
    },
    maxTimeRange() {
      return this.validation.time_settings[0].max_hours || 24;
    },
  },
  watch: {
    current: {
      immediate: true,
      handler(val, oldVal) {
        // change formData
        this.$set(this.formData, 'crop', val);
        // set defaults dates
        if (oldVal) {
          if (this.selectedItemCrop.default_start) {
            this.defaultStartDate = this.$moment(this.selectedItemCrop.default_start).toDate();
          }
          if (this.selectedItemCrop.default_end) {
            this.defaultEndDate = this.$moment(this.selectedItemCrop.default_end).toDate();
          }
        }

        // reset modal popup
        const { timeSlider } = this.$refs;
        if (timeSlider) timeSlider.resetModal();
      },
    },
    crops: {
      immediate: true,
      handler() {
        // set defaults dates
        if (this.selectedItemCrop.default_start) {
          this.defaultStartDate = this
            .$moment(this.formData.startDate || this.selectedItemCrop.default_start).toDate();
        }
        if (this.selectedItemCrop.default_end) {
          this.defaultEndDate = this
            .$moment(this.formData.endDate || this.selectedItemCrop.default_end).toDate();
        }
      },
    },
    volksfest: {
      handler(val) {
        this.$set(this.formData, 'volksfest', val);
      },
    },
    validation: {
      handler(validation) {
        const default_start_hour = validation?.time_settings[0].default_start_hour;
        const default_end_hour = validation?.time_settings[0].default_end_hour;
        if (!default_start_hour || !default_end_hour) return;

        this.timeRange = [default_start_hour, default_end_hour];

        LogService.log('start end hour', default_start_hour, default_end_hour);
      },
    },
  },
  mounted() {
    LogService.log('form mounted');
    const type = this.current in this.$store.state.settings.calendar
      ? this.current
      : Object.keys(this.$store.state.settings.calendar)[0];
    const cal = this.$store.state.settings.calendar[type] || {};
    const default_start_hour = cal?.time_settings[0].default_start_hour;
    const default_end_hour = cal?.time_settings[0].default_end_hour;
    if (!default_start_hour || !default_end_hour) return;
    this.timeRange = [default_start_hour, default_end_hour];
  },
  methods: {
    periodHumanize,
  },
};
</script>
<style lang="scss">
  .wet-order-form {
    max-width: 920px;
    margin: 0 auto;
  }
  .wet-order-form-decor {
    position: relative;
  }
  .transparent-border {
    border: 1px solid transparent;
  }
  .wet-order-form-decor input {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
  }
  .wet-order-form-decor:before {
    content: "/";
    text-align: center;
    width: -webkit-calc(100% - 30px);
    width: calc(100% - 30px);
    height: 2.33rem;
    line-height: calc(2.33rem - 2px);
    color: rgba(25, 28, 30, 0.8);
    background: #fff;
    border: 1px solid var(--border-input-color);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.15);
    -webkit-border-radius: 2rem;
    border-radius: 2rem;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: 1.63rem;
  }
  @media only screen and (max-width: 992px) {
    .wet-order-form-decor:before {
      bottom: 1.2rem;
    }
  }
  @media only screen and (max-width: 576px) {
    .wet-order-form-decor:before {
      bottom: .8rem;
    }
  }
</style>
