<template>
  <div class="video_box post">
    <button type="button"
      @click.prevent="$emit('videomodal', true)"
      class="video_button button "
    >
      <img
        alt=""
        :src="data.video_icon"
        @load="$event.target.classList.toggle('empty-img', false)"
        class="video_icon empty-img"
        width="22"
        height="22"
      >
      <div class="video_text empty-ph">{{data.video_text_drought}}</div>
    </button>
  </div>
</template>
<script>
export default {
  props: {
    data: Object,
  },
};
</script>
<style lang="scss">
  .video_text {
    box-sizing: border-box;
    margin-left: 10px;
    font-size: 18px;
    line-height: 1;
    min-width: 11rem;
  }
</style>
