<template>
  <div>
    <datepicker-component
      id="datepicker-day"
      calId="datepicker-day"
      name="datepickerStart"
      :language="calendLang"
      v-model="selected"
      :disabled-dates="disabledDates"
      calendar-class="datepicker"
      wrapper-class="datepicker-inline"
      ref="calendar"
      :highlighted="{
        dates: dates && dates.map((d) => $moment(d).toDate())
      }"
      :inline="true"
      :open-date="openDate"
      :monday-first="true"
    ></datepicker-component>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import common from '@/mixins/common';

export default {
  mixins: [common],
  props: {
    initDates: Array, /** Array<Moment> */
    calendLang: Object,
    startText: String,
    scenario: String,
  },
  data() {
    return {
      selected: null,
      calendarShowed: false,
      openDate: this.$moment().toDate(),
      disabledDates: {},
      dates: [],
      pressedKeys: [],
    };
  },
  computed: {
    ...mapState(['strings']),
    validation() {
      let type = this.scenario;
      switch (this.$route.name) {
      case 'drought':
      case 'drought2':
        type = 'crop';
        break;
      case 'custom':
        type = 'custom';
        break;
      default:
        break;
      }
      return this.$store.state.settings.calendar[type];
    },
    shiftPressed() {
      return this.pressedKeys.includes('shift');
    },
  },
  watch: {
    selected: {
      handler(v) {
        const dates = this.dates?.map((d) => this.$moment(d).format('YYYY-MM-DD'));
        const selected = this.$moment(v).format('YYYY-MM-DD');
        const newDates = this.$lodash.xor(dates, [selected]).map((d) => this.$moment(d));

        this.validationProcess(this.validation, newDates);
      },
    },
    initDates: {
      immediate: true,
      handler(dates) {
        this.validationProcess(this.validation, dates);
        this.openFirstDate(this.dates);
      },
    },
    validation: {
      immediate: true,
      handler() {
        const dates = this.dates.length > 0 ? this.dates : this.initDates;
        this.validationProcess(this.validation, dates);
        this.openFirstDate(this.dates);
      },
    },
    dates: {
      handler(dates) {
        this.$emit('dates', dates);
      },
    },
  },
  created() {
    window.addEventListener('keydown', this.onKeydown);
    window.addEventListener('keyup', this.onKeyup);
  },
  destroyed() {
    window.removeEventListener('keydown', this.onKeydown);
    window.removeEventListener('keyup', this.onKeyup);
  },
  methods: {
    getValidatedData(validation = {}, dates = []) {
      const {
        max_contract_start_postponement,
        max_date_range,
        min_contract_start_postponement,
        min_contract_start_postponement_less_29,
      } = validation;
      if (!max_date_range) {
        return {
          to: this.$moment(),
          from: this.$moment(),
          dates: [],
        };
      }

      let newDates = [];
      let less = true;

      // set default borders
      let defaultTo = this.$moment.today().add(min_contract_start_postponement_less_29, 'days');
      const defaultFrom = this.$moment.today().add(max_contract_start_postponement, 'days');

      const defaults = {
        to: defaultTo.clone().add(1, 'days'),
        from: defaultFrom,
        dates: [],
      };

      if (dates.length === 0) return defaults;

      // check start for valid
      if (this.$moment.min(dates).isAfter(this.$moment.today().add(max_contract_start_postponement, 'days'), 'days')) {
        return defaults;
      }

      // get bordered dates
      newDates = dates.filter((d) => d.isAfter(defaultTo, 'days')
        && d.isBefore(defaultFrom.clone().add(max_date_range, 'days'), 'days'));

      if (newDates.length === 0) return defaults;

      // check range for less
      const newDatesRangeLen = this.$moment.max(newDates).diff(this.$moment.min(newDates), 'days') + 1;
      const startIsLess = this.$moment.min(newDates).diff(this.$moment.today(), 'days') <= min_contract_start_postponement;
      if (newDatesRangeLen >= min_contract_start_postponement && startIsLess) {
        less = false;
        this.showPopup();
      }

      // deselect dates before today() + min_contract_start_postponement
      const minStart = less
        ? min_contract_start_postponement_less_29
        : min_contract_start_postponement;
      newDates = newDates.filter((d) => d.isAfter(this.$moment.today().add(minStart, 'days'), 'days'));

      // deselect dates after min(dates) + max_date_range
      newDates = newDates.filter((d) => d < this.$moment.min(newDates).clone().add(max_date_range, 'days'));

      // set 'default' given the 'less'
      defaultTo = this.$moment.today().add(minStart, 'days');

      // set borders
      const to = this.$moment.max(defaultTo, this.$moment.max(newDates).clone().subtract(max_date_range, 'days')).clone().add(1, 'days');
      const from = this.$moment.min(defaultFrom, this.$moment.min(newDates)).clone().add(max_date_range - 1, 'days');

      return {
        to,
        from,
        dates: newDates,
      };
    },
    validationProcess(validation, datesParam) {
      const { to, from, dates } = this.getValidatedData(validation, datesParam);
      this.disabledDates = {
        from: from.toDate(),
        to: to.toDate(),
      };
      this.dates = dates;
    },
    openFirstDate(dates) {
      if (dates?.length > 0) {
        if (!(dates.some((date) => this.$moment(date).isSame(this.openDate)))) {
          const [openDate] = dates.map((d) => this.$moment(d).toDate()).sort((a, b) => a - b);
          this.openDate = this.$moment(this.disabledDates.to).isAfter(openDate)
            ? this.disabledDates.to || this.$moment().toDate() : openDate;
        }
      } else if (this.disabledDates.to) {
        this.openDate = this.disabledDates.to;
      }
    },
    closeInfo() {
      //
    },
    onKeydown(e) {
      // set shift key
      if (/shift/gi.test(e.code) && !this.pressedKeys.includes('shift')) {
        this.pressedKeys = [...this.pressedKeys, 'shift'];
      }
    },
    onKeyup(e) {
      // remove shift key
      if (/shift/gi.test(e.code) && this.pressedKeys.includes('shift')) {
        this.pressedKeys = this.pressedKeys.filter((k) => k !== 'shift');
      }
    },
    showPopup() {
      // show the popup
      const message = this.format(
        this.strings.commonApp.calendar.error_more_than_postponement,
        this.validation.min_contract_start_postponement,
        Number.parseInt(this.validation.min_contract_start_postponement, 10) === 1
          ? this.strings.commonApp.day : this.strings.commonApp.days,
        this.validation.min_contract_start_postponement,
      );
      const modal = {
        id: 'wet-dates-postponement-modal',
        content: `<div class="text-center">${message}</div>`,
        actions: [
          {
            name: 'wet-dates-postponement-ok',
            title: this.strings.commonApp?.ok,
            event: 'close',
          },
        ],
      };
      this.$store.dispatch('addModal', modal);
    },
  },
};
</script>
