<template>
  <ul
    class="list-unstyled"
    :style="{
      maxWidth: '500px',
      width: '100%',
    }"
  >
    <li v-for="(item, i) in items" :key="i" class="mb-2" >
      <button
        :id="`wet-volksfest-button-${i + 1}`"
        class="wet-volksfest-list-item"
        :class="{'active': value.id === item.id}"
        @click.prevent="selected = item"
      >
        <div class="font-weight-bold">{{item.name}}</div>
        <div class="wet-note">{{subTitle(item)}}</div>
      </button>
    </li>
  </ul>
</template>
<script>
import { periodHumanize } from '@/common/snippets';

export default {
  props: {
    items: Array,
    value: Object,
    dates: Array, /** Array<Moment> */
  },
  data() {
    return {
      selected: {},
    };
  },
  watch: {
    selected: {
      handler(selected) {
        if (!selected) return;
        this.$emit('input', selected);
        const dates = this.itemToDates(selected);
        this.$emit('update:dates', this.filteredDates(dates));
      },
    },
    items: {
      immediate: true,
      handler() {
        [this.selected] = this.items;
      },
    },
  },
  methods: {
    periodHumanize,
    subTitle(item) {
      const dates = this.itemToDates(item);
      const intervals = this.periodHumanize(this, dates);
      return `${intervals}, ${item.city}`;
    },
    itemToDates(item) {
      // range to array of dates
      return Array.from(this.$moment.range(item.start_date, item.end_date).by('days'));
    },
    filteredDates(dates) {
      // filter by weekdays
      let fDates = dates;
      if (dates.length > 3) {
        /**
         * 0, 5, 6 - is sat., fri. and sun.
         */
        fDates = dates.filter((moment) => [0, 5, 6].includes(moment.day()));
      }
      return fDates;
    },
  },
};
</script>
<style lang="scss">
  .wet-volksfest-list-item {
    flex: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: 1px solid var(--grey-light);
    box-shadow: 0 0 .5rem rgba(0,0,0,.1);
    fill: currentColor;
    padding: .5rem 1rem;
    border-radius: .5rem;
    cursor: pointer;
    text-align: center;
    transition: all .3s ease-in-out;
  }
  .wet-volksfest-list-item:hover {
    color: var(--secondary);
    box-shadow: 0 5px .5rem rgba(0,0,0,.2);
  }
  .wet-volksfest-list-item.active {
    background-color: var(--secondary);
    box-shadow: 0 0 .5rem #{"rgba(var(--secondary-rgb), 0.5)"};
    color: white;
    border-color: transparent;
  }
  .wet-volksfest-list-item.active .wet-note {
    color: white;
  }
</style>
