<template>
  <div class="row wet-order-form-decor">
    <div class="col-md-6 col-sm-6 col-6">
      <div class="form-group">
        <label
          class="form-group-title hide-tb form-group-start empty-ph"
        >{{startText}}</label>
        <datepicker-component
          id="datepickerStart"
          calId="datepicker-start"
          name="datepickerStart"
          :language="calLang"
          :value="startDate"
          @input="(v) => onChangeStartDate(v)"
          :disabled-dates="disabledStart"
          :required="true"
          calendar-class="datepicker"
          wrapper-class="transparent-border"
          input-class="period-start datepicker-wetterheld"
          :disabled="crops[0] && !crops[0].id"
          :highlighted="{
            dates: [$moment(startDate).toDate()],
          }"
          :monday-first="true"
        ></datepicker-component>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 col-6">
      <div class="form-group">
        <label
          class="form-group-title hide-tb form-group-end empty-ph"
        >{{endText}}</label>
        <datepicker-component
          id="datepickerEnd"
          calId="datepicker-end"
          :language="calLang"
          :value="endDate"
          @input="(v) => setEndDate(v)"
          :disabled-dates="disabledEnd"
          :required="true"
          calendar-class="datepicker datepicker-end"
          wrapper-class="transparent-border"
          input-class="period-end datepicker-wetterheld"
          :disabled="crops[0] && !crops[0].id"
          :highlighted="{
            dates: [$moment(endDate).toDate()],
          }"
          :monday-first="true"
        ></datepicker-component>
      </div>
    </div>
  </div>
</template>
<script>
import {
  en, de, it, fr, pl,
} from 'vuejs-datepicker/dist/locale';
import periodAlt from '@/components/sections/periodAlt.vue';

export default {
  extends: periodAlt,
  props: {
    calendLang: [Object, String],
  },
  computed: {
    validation() {
      const type = this.current in this.$store.state.settings.calendar
        ? this.current
        : Object.keys(this.$store.state.settings.calendar)[0];
      return this.$store.state.settings.calendar[type] || {};
    },
    calLang() {
      return typeof this.calendLang === 'string' ? this.langs?.[this.calendLang] || {} : this.calendLang;
    },
    langs() {
      return {
        en, de, it, fr, pl,
      };
    },
  },
};
</script>
