<template>
  <div class="wet-order-grow">
    <div class="row  wet-order-grow-slider" style="position:relative;">
      <div class="col">
        <button
          @click="sliderLeft"
          id="drought-slider-arrow-left"
          class="slick-prev slick-arrow"
          type="button"
          style="display: block;"
        ></button>
        <div
          class="row flex-nowrap slide-row"
          :class="{
            'justify-content-md-center': crops.length < 4,
          }"
        >
          <div
            class="col-lg-3 col-md-3 col-sm-6 col-6 slide"
            v-for="(item, id) in crops"
            :key="item.id || id"
          >
            <div
              :id="`wet-prod-slider-${id + 1}`"
              @click="$emit('update:current', item.id || '')"
              class="wet-order-grow-item"
              :class="current === item.id ? 'current' : ''"
            >
              <img
                class="wet-order-grow-ico empty-img"
                width="100%"
                height="100%"
                @load="$event.target.classList.toggle('empty-img', false)"
                :src="getImage(item)"
                :srcset="getImageSet(item)"
                sizes="(max-width: 576px) 100px, (max-width: 1500px) 150px, 208px"
                alt=""
              >
              <div class="wet-order-grow-text empty-ph">{{item.title}}</div>
            </div>
          </div>
        </div>
        <button
          @click="sliderRight"
          id="drought-slider-arrow-right"
          class="slick-next slick-arrow"
          type="button"
          style="display: block;"
        ></button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import LogService from '@/services/LogService';

export default {
  props: {
    current: String,
    crops: {
      type: Array,
      default: () => Array(4).fill({}),
    },
  },
  computed: {
    ...mapState(['strings']),
    currentId() {
      return this.crops.findIndex((el) => el.id === this.current);
    },
  },
  mounted() {
    //
    this.rowScrollHandler();
  },
  destroyed() {
    //
  },
  watch: {
    current() {
      // update X scroll on .slide-row
      if (document) {
        const row = document.querySelector('.wet-order-grow-slider .slide-row');
        const slide = document.querySelector('.wet-order-grow-slider .slide');
        // const id = this.crops.findIndex(el => el.id === val)
        if (row && slide) {
          row.scrollTo({
            left: slide.offsetWidth * this.currentId,
            behavior: 'smooth',
          });
        }
      }
    },
    crops(newCrops) {
      LogService.log('slider crops', newCrops);
    },
  },
  methods: {
    sliderLeft() {
      // event on slider left btn click
      if (!this.crops[0].id) return;
      if (this.currentId === 0) {
        this.$emit('update:current', this.crops[this.crops.length - 1].id);
      } else this.$emit('update:current', this.crops[this.currentId - 1].id);
    },
    sliderRight() {
      // event on slider right btn click
      if (!this.crops[0].id) return;
      if (this.currentId < this.crops.length - 1) {
        this.$emit('update:current', this.crops[this.currentId + 1].id);
      } else this.$emit('update:current', this.crops[0].id);
    },
    rowScrollHandler() {
      // X scroll on '.slide-row' when mouse wheel event calling
      const row = document.querySelector('.wet-order-grow-slider .slide-row');
      const wheel = (e) => {
        if (this.crops.length < 5) return;
        e.preventDefault();
        row.scrollLeft += e.deltaY;
      };
      if (row) {
        row.addEventListener('wheel', wheel, { passive: false });
      }
    },
    getImage(item) {
      // compute image url from object
      let url = '#';
      if ('photo_url' in item) {
        url = item.photo_url;
      }
      return url;
    },
    getImageSet(item) {
      // compute images srcset from object
      let srcset = '';
      if ('photo_srcset' in item) {
        srcset = this.$arrToSrcset(item.photo_srcset);
      }
      return srcset;
    },
  },
};
</script>
<style lang="scss">
  /*! slider component style */
  .slide-row {
    overflow-x: auto;
    // -webkit-overflow-scrolling: touch;
    // scroll-behavior: smooth;
    -ms-overflow-style: none;
  }
  .slide-row::-webkit-scrollbar {
    display: none;
  }
  .slick-prev,
  .slick-next {
    opacity: 0.5;
    width: 40px;
    height: 40px;
    -webkit-transition: all ease-out 0.1s;
    -o-transition: all ease-out 0.1s;
    transition: all ease-out 0.1s;
  }
  .slick-prev:before,
  .slick-next:before {
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 1;
  }
  @media only screen and (max-width: 576px) {
    .slick-prev:before,
    .slick-next:before {
      -webkit-background-size: cover;
      background-size: cover;
    }
  }
  .slick-prev:hover,
  .slick-next:hover {
    -webkit-transition: all ease-out 0.1s;
    -o-transition: all ease-out 0.1s;
    transition: all ease-out 0.1s;
    opacity: 1;
  }
  .slick-prev:focus,
  .slick-next:focus {
    outline: none;
  }
  .slick-prev:active,
  .slick-next:active {
    opacity: 0.8;
  }
  .slick-prev {
    left: 35px;
  }
  @media only screen and (max-width: 992px) {
    .slick-prev {
      left: 0;
    }
  }
  @media only screen and (max-width: 576px) {
    .slick-prev {
      left: -15px;
    }
  }
  .slick-next {
    right: 35px;
  }
  @media only screen and (max-width: 992px) {
    .slick-next {
      right: 0;
    }
  }
  @media only screen and (max-width: 576px) {
    .slick-next {
      right: -15px;
    }
  }
  .slick-next,
  .slick-prev{
    z-index: 3;
  }
  .wet-order-grow-slider .slick-next{
    right: -30px;
  }
  .wet-order-grow-slider .slick-prev{
    left: -30px;
  }
  @media screen and (max-width: 1200px){
    .wet-order-grow-slider .slick-next{
      right: 0px;
    }
    .wet-order-grow-slider .slick-prev{
      left: 0px;
    }
  }
</style>
